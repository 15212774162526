import { defineStore } from 'pinia'
import { layout } from '@o2oa/component'
import { getMenuByRole, getMenuTree } from '../axios'
import _ from 'lodash'

const filterMenuTree = (list) => {
  let newList = list.filter((item) => {
    if (item.children) {
      item.children = filterMenuTree(item.children)
      if (item.children.length === 0) {
        delete item.children
      }
    }
    return item.menuType !== 'button'
  })
  return newList
}

const filterPermissionsMenuList = (list, permissions) => {
  let permissionsArray = []
  if (Array.isArray(permissions)) {
    permissionsArray = [...permissions]
  } else {
    for (let key in permissions) {
      permissionsArray.push(...permissions[key])
    }
  }
  let filterList = list
    .map((item) => _.cloneDeep(item))
    .filter((item) => {
      if (item.children) {
        item.children = filterPermissionsMenuList(item.children, permissionsArray)
      }
      if(item.menuType === 'menu' && !item.component){
        return false
      }
      if (item.alias) {
        return permissionsArray.includes(item.alias)
      } else {
        return true
      }
    })
  return filterList
}

export const usePermissionStore = defineStore({
  id: 'permission',
  persist: {
    enabled: true
  },
  state: () => {
    return {
      menuList: [],
      permissionList: [],
      permissions: {},
      menuTypeList: [
        {
          id: 'directory',
          name: '菜单'
        },
        {
          id: 'menu',
          name: '页面'
        },
        {
          id: 'button',
          name: '按钮'
        }
      ],
      module: null,
      homeModule: null
    }
  },
  getters: {
    allPermissionsMenuList() {
      let allList = filterPermissionsMenuList(this.menuList, this.permissions)
      return this.filterMenuList(allList)
    },
    permissionsMenuList(state) {
      // return filterPermissionsMenuList(state.menuList,state.permissions)
      return filterPermissionsMenuList(this.activeMenuList, this.activePermissions)
    },
    moduleList(state) {
      let list = state.menuList.filter((item) => {
        if (item.module === 'Mobile') {
          return false
        }
        return state.permissions[item.module]
      })
      return list
    },
    activeModule(state) {
      if (state.module) {
        let activeModule = this.menuList.find((item) => item.module === state.module)
        if (activeModule) {
          return activeModule
        }
      }
      return {}
    },
    activePermissions(state) {
      if (state.module && state.permissions[state.module]) {
        return state.permissions[state.module]
      }
      return []
    },
    allPermissions(state){
      let list = []
      for(let key in state.permissions){
        list.push(...state.permissions[key])
      }
      return list
    },
    activeMenuList(state) {
      if (this.activeModule.children) {
        return this.activeModule.children
      }
      return []
    },
    routeList() {
      let routeList = this.filterMenuList(this.activeMenuList)
      return routeList
    }
  },
  actions: {
    async initMenuTree() {
      await this.getMenuTree()
    },
    async getMenuTree() {
      await getMenuTree({
        // 不传查全部
        // module: this.module
      }).then((res) => {
        if (res) {
          let list = res.data.children || []
          let tree = this.dealMenuTree(list)
          // 删除按钮级
          this.menuList = filterMenuTree(_.cloneDeep(tree))
          // 返回所有权限表
          this.permissionList = this.filterPermissionList(_.cloneDeep(tree))
        }
      })
    },
    async initPermissions() {
      this.permissions = {}
      if (layout.user.roleList && Array.isArray(layout.user.roleList)) {
        let roleList = layout.user.roleList
        if (roleList.length > 0) {
          let ids = roleList.join(',')
          await this.getPermissions(ids)
        }
      }
    },
    async initRouter(router,next,to) {
      let routeList = this.allPermissionsMenuList
      const modulesFiles = import.meta.glob('@/views/**/*.vue')
      const filesMap = {}
      for (const key of Object.keys(modulesFiles)) {
        let keyArray = key.split('/')
        let fileName = `${keyArray[keyArray.length - 2]}/${keyArray[keyArray.length - 1]}`
        filesMap[fileName] = modulesFiles[key]
      }
      this.setRouterAll(routeList, filesMap, router)
      router.isReady().then(() => {
        let toRouteName = to.path.replace('/', '')
        if (router.hasRoute(toRouteName)) {
          let lastRouterItem = routeList.find(item=>item.path === toRouteName)
          if(lastRouterItem){
            this.module = lastRouterItem.module
          }
          router.replace(to.fullPath)
        }else{
          router.replace({
            path: `/Home`
          })
        }
      })
    },
    setRouterAll(routeList, filesMap, router) {
      for (const item of routeList) {
        if (item.component) {
          this.setRouterItem(item, filesMap, router)
        }
      }
    },
    setRouterItem(item, filesMap, router) {
      if (item.component) {
        let { component, props } = item
        let meta = {
          name: item.name
        }
        if (item.meta) {
          meta = {
            ...meta,
            ...JSON.parse(item.meta)
          }
        }
        if (props) {
          try {
            props = JSON.parse(props)
          }catch(e)
          {
            console.error(item);
          }
        }
        if (component) {
          let filePath = `${component}.vue`
          if(filesMap[filePath]){
            let route = {
              path: `/${item.path}`,
              name: item.path,
              component: async () => await filesMap[filePath](),
              props: props || {},
              meta: meta
            }
            if(item.module === 'Pad'){
              router.addRoute('PadLayout', route)
            }else{
              router.addRoute('PageLayout', route)
            }
            // if (item.module === 'CustomerService') {
            //   router.addRoute('CustomerService', route)
            // } else {
            //   router.addRoute('PageLayout', route)
            // }
          }
        }
      }
    },
    goFirstRouter(router) {
      let routeList = this.filterMenuList(this.activeMenuList)
      if (routeList.length > 0) {
        // let goRouter = routeList[0]
        let firstRoute = routeList.find((item) => {
          return router.hasRoute(item.path)
        })
        if (firstRoute) {
          router.push({
            name: firstRoute.path
          })
        }
      }
    },
    filterMenuList(list, returnList = []) {
      list.forEach((item) => {
        if (item.menuType === 'menu') {
          returnList.push(item)
        } else {
          if (item.children) {
            this.filterMenuList(item.children, returnList)
          }
        }
      })
      return returnList
    },
    async getPermissions(id) {
      await getMenuByRole({ id }).then((res) => {
        if (res) {
          let list = res.data.map((item) => item.menuId)
          this.permissions = this.findPermissions(list)
          // this.setModule()
        }
      })
    },
    setModuleByRouter(name) {
      let checkItem = this.permissionList.find((item) => item.path === name)
      if (checkItem) {
        this.module = checkItem.module
      }
    },
    setModule() {
      if (!this.module) {
        let checkMenu = this.menuList.find((item) => {
          return this.permissions[item.module]
        })
        if (checkMenu) {
          this.module = checkMenu.module
        }
      } else {
        if (!this.permissions[this.module]) {
          this.setModule()
        }
      }
    },
    findPermissions(list) {
      let permissions = {}
      let map = {}
      list.forEach((item) => {
        map[item] = true
      })
      let setPermissionItem = (item) => {
        // if (item.module === this.homeModule || (map[item.id] && item.alias)) {
        if (item.path === 'HomePageSystemHome' || (map[item.id] && item.alias)) {
          if (!permissions[item.module]) {
            permissions[item.module] = []
          }
          permissions[item.module].push(item.alias)
        }
        if (item.children && item.children.length > 0) {
          item.children.forEach((cItem) => {
            setPermissionItem(cItem)
          })
        }
      }
      this.permissionList.forEach((item) => {
        setPermissionItem(item)
      })
      return permissions
      // return aliasList;
    },
    filterPermissionList(list, returnList = []) {
      list.forEach((item) => {
        if (item.menuType === 'directory') {
          if (item.children) {
            this.filterPermissionList(item.children, returnList)
          }
        } else {
          if (item.alias) {
            returnList.push(item)
          }
        }
      })
      return returnList
    },
    getRouteById(id) {
      let active = this.routeList.find((item) => item.id === id)
      return active || {}
    },
    getRouteByPath(path) {
      let active = this.routeList.find((item) => item.path === path)
      return active || {}
    },
    dealMenuTree(list) {
      list.forEach((item) => {
        // if(item.parent.id === 1 && item.name === '首页'){
        if (item.parent.id === 1 && item.module === 'HomePage') {
          this.homeModule = item.module
        }
        if (item.menuType === 'menu') {
          item.alias = `${item.module}.${item.alias}`
          item.path = `${item.module}${item.path}`
        }
        if (item.children) {
          item.children = this.dealMenuTree(item.children)
          if (item.children.length === 0) {
            delete item.children
          }
        }
        return true
      })
      return list
    }
  }
})
