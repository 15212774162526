import { defineStore } from 'pinia'
import { usePermissionStore } from "@/store/permission";
import { layout } from "@o2oa/component";
import _ from 'lodash'
let permissionStore
export const useStorageStore = defineStore({
  id: 'storage',
  persist: {
    enabled: true
  },
  state: () => {
    return {
      agreeInstructionsList: [],
      historyParams: [],
      routerHistory: ['/HomePageSystemHome'],
      defaultRouterHistory: ['/HomePageSystemHome']
      // [environment.module]: {}
    }
  },
  getters: {},
  actions: {
    addRouterHistory(path){
      const match = path.match(/[A-Z][a-z]*/);
      if (match && match[0] !== 'Pad') {
        if(this.routerHistory.includes(path)){
          return
        }
        this.routerHistory.push(path)
      }
    },
    deleteRouterHistory(path){
      this.routerHistory = this.routerHistory.filter(item=>item!==path)
    },
    deleteOtherRouterHistory(path){
      this.routerHistory = this.routerHistory.filter(item=>{
        if(item===path){
          return true
        }
        if(this.defaultRouterHistory.includes(item)){
          return true
        }
        return false
      })
    },
    clearRouterHistory(){
      this.routerHistory = _.cloneDeep(this.defaultRouterHistory)
    },
    setAgreeInstructions(username) {
      if(!this.hasAgreeInstructions(username)){
        this.agreeInstructionsList.push(username)
      }
    },
    hasAgreeInstructions(username) {
      return this.agreeInstructionsList.includes(username)
    },
    getStorageByKey(nameSpace, key) {
      if(!permissionStore){
        permissionStore = usePermissionStore()
      }
      let module = permissionStore.module
      let userId = layout.user.id
      let checkItem = this.historyParams.find((item)=>{
        return item.module === module && item.userId === userId && item.nameSpace === nameSpace && item.key === key
      })
      if(checkItem){
        return checkItem.value
      }
      return null
    },
    clearStorageByKey(nameSpace,key){
      if(!permissionStore){
        permissionStore = usePermissionStore()
      }
      let module = permissionStore.module
      let userId = layout.user.id
      let checkItem = this.historyParams.find((item)=>{
        return item.module === module && item.userId === userId && item.nameSpace === nameSpace && item.key === key
      })
      if(checkItem){
        delete checkItem.value
      }
    },
    /**
     * 添加参数方法
     * 一级键值名为模块，二级键值名为namespace，三级键值名为参数key
     * @param nameSpace
     * @param key
     * @param value
     */
    setStorageByKey(nameSpace, key, value) {
      if(!permissionStore){
        permissionStore = usePermissionStore()
      }
      let module = permissionStore.module
      let userId = layout.user.id
      let checkItem = this.historyParams.find((item)=>{
        return item.module === module && item.userId === userId && item.nameSpace === nameSpace && item.key === key
      })
      if(checkItem){
        checkItem.value = value
      }else{
        this.historyParams.push({
          userId,
          module,
          nameSpace,
          key,
          value
        })
      }
    }
  }
})

